import withPodcast from '../utils/withPodcast';
import { PageContainer } from '../components/page-container/PageContainer';
import { TopNav } from '../components/top-nav/TopNav';
import { ManagePodcast } from '../components/manage-podcast/ManagePodcast';
import { ManagePodcastTab, ModalType, Podcast } from '../types';
import React, { useRef, useState } from 'react';
import { Button } from '../components/button/Button';
import s from './ManagePodcastEmail.module.css';
import { useDispatch } from 'react-redux';
import { showModal } from '../redux/actions/modal';
import { error, success } from '../utils/Notifications';
import { BoundTextInput } from '../components/bound-text-input/BoundTextInput';
import { call } from '../utils/ApiManager';
import TextareaAutosize from 'react-textarea-autosize';
import { FaImage } from 'react-icons/fa';
import { useFirebase } from 'react-redux-firebase';
import { useTypedSelector } from '../redux/reducers';
import { uploadAttachment } from '../utils/utilities';
import { Oval } from 'react-loader-spinner';
import cn from 'classnames';

const ManagePodcastEmail: React.FC<any> = (props) => {
  const [content, setContent] = useState('');
  const [subject, setSubject] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadingImg, setIsUploadingImg] = useState(false);
  const imgRef = useRef(null);
  const firebase = useFirebase();
  const auth = useTypedSelector((state) => state.firebase.auth);
  const {
    podcast,
    profile,
  }: {
    podcast: Podcast;
    profile: any;
  } = props;

  const sendEmails = async () => {
    setIsLoading(true);
    try {
      await call('/sendnotification')({
        podcastId: podcast.id,
        subject,
        content,
      });
      success('Tilkynning hefur verið send.');
      setContent('');
      setSubject('');
    } catch (err: any) {
      error(err.message || 'Ekki tókst að senda tilkynningu.');
    } finally {
      setIsLoading(false);
    }
  };

  const dispatch = useDispatch();
  return (
    <PageContainer>
      <TopNav />
      <ManagePodcast context={ManagePodcastTab.EMAIL} podcast={podcast}>
        <div className={s.top}>
          Hér getur þú sent út tilkynningu í tölvupósti til allra virkra
          áskrifenda.
        </div>
        <BoundTextInput
          label={'Fyrirsögn tölvupósts (e. subject)'}
          placeholder={'Skrifaðu fyrirsögn'}
          onChange={(e) => setSubject(e.target.value)}
          value={subject}
        />
        <div
          className={
            'tw-w-full tw-mt-4 tw-resize-none tw-bg-[#fafcfd] focus-visible:tw-outline-0 tw-border tw-border-[#CDCDCD] tw-rounded-md'
          }
        >
          <div className={'tw-p-4 tw-border-b tw-border-[#CDCDCD]'}>
            <button
              className={'tw-relative tw-group'}
              onClick={() => {
                if (imgRef.current && !isUploadingImg) {
                  (imgRef?.current as any).click();
                }
              }}
            >
              <span
                className={cn(
                  'tw-transition-colors tw-absolute tw-w-[calc(100%_+_16px)] tw-h-[calc(100%_+_16px)] tw-rounded-lg tw--translate-x-1/2 tw-top-1/2 tw-left-1/2 tw--translate-y-1/2 tw-z-0',
                  {
                    'group-hover:tw-bg-gray-200': !isUploadingImg,
                  },
                )}
              />
              <Oval
                visible={isUploadingImg}
                height="28"
                secondaryColor={'#848484'}
                width="28"
                color="#211147"
                strokeWidth={3}
                wrapperClass="tw-absolute tw--translate-x-1/2 tw-top-1/2 tw-left-1/2 tw--translate-y-1/2"
              />
              <FaImage className={'tw-z-10 tw-relative'} />
              <input
                ref={imgRef}
                className={'tw-hidden'}
                type="file"
                id="image"
                accept="image/*"
                onChange={async (e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    setIsUploadingImg(true);
                    const url = await uploadAttachment(
                      file as File,
                      firebase,
                      auth?.uid,
                      false,
                    );
                    setIsUploadingImg(false);
                    setContent(
                      (content) =>
                        `${content}<img src="${url}" />`,
                    );
                  }
                  e.target.value = '';
                }}
              />
            </button>
          </div>
          <TextareaAutosize
            value={content}
            maxRows={30}
            className={
              'tw-w-full tw-p-4 tw-bg-transparent tw-resize-none focus-visible:tw-outline-0'
            }
            minRows={8}
            disabled={isLoading}
            placeholder={'Skrifaðu hér...'}
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
        <div className={s.button}>
          <Button
            disabled={!content || !subject || isLoading}
            onClick={() =>
              dispatch(
                showModal(ModalType.CONFIRM_MODAL, {
                  color: 'green',
                  title: 'Senda tilkynningu?',
                  body: 'Tilkynning verður send í tölvupósti á alla virka áskrifendur',
                  actionText: 'Staðfesta',
                  action: () => sendEmails(),
                }),
              )
            }
          >
            Senda tölvupóst til allra áskrifenda
          </Button>
        </div>
      </ManagePodcast>
    </PageContainer>
  );
};

export default withPodcast(ManagePodcastEmail);
